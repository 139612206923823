.environment {
  background: red;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 2em;
  text-align: center;
  color: white;
  padding: 1em;
  border-top-left-radius: 10px;
  opacity: 0.3;
  pointer-events: none;
  z-index: 10000;
}

.app-links {
  h4 {
    margin-bottom: 1em;
  }

  a {
    margin: 0 1.5em 1em;
    display: inline-block;

    img {
      max-width: 200px;
      max-height: 54px;
      border-radius: 3px;
    }

    &:hover img {
      opacity: 0.8;
    }
  }
}

.home-index .card-body {
  padding: 0;
}

.btn-primary,
.btn-success {
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: rgba(255 255 255 / 80%);
  }
}
