@use "sass:color";

$background-color: $co2m-dark-blue;

.app-switcher {
  padding: 12px 15px;
  background-color: color.adjust($background-color, $lightness: 10%);

  &:hover {
    background-color: color.adjust($background-color, $lightness: 15%);
  }

  .application-title {
    color: white;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.92rem;
  }
}

.dropdown-app-switcher {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  min-width: 200px;
  margin-top: -1px;

  img {
    height: 45px;
  }

  .smarttrackers-logo {
    display: block;
    margin: 5px 4px 10px;
  }

  .title {
    border-bottom: 1px solid #eee;
    padding-left: 15px;
    padding-bottom: 10px;
    font-size: 14px;
  }

  .dropdown-item {
    padding-left: 5px;
    padding-right: 0;
  }
}

a.trigger-app-switcher {
  color: white;
  width: 20px;
  float: right;
  margin: 2px -3px 0 12px;
  display: block;
}
