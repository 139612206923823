.top-nav {
  margin-bottom: 4em;
  background: $co2m-dark-blue;
  border-bottom: 1px solid #d9dee4;
  min-height: 51px;
  padding: 0;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1em;
  }

  .navbar-toggler {
    border-color: $white;
    margin-right: 5px;

    &:hover,
    &:focus {
      background: rgba(255 255 255 / 20%);
    }

    .icon-bar {
      background-color: $white;
    }
  }

  .language-switcher img {
    width: 1.5em;
    margin: 0 1px 2px 0;
  }

  .navbar-collapse {
    @include media-breakpoint-down(sm) {
      padding: 0.5em;
    }
  }

  .navbar-nav {
    a.nav-link:focus,
    a.nav-link:hover {
      background: #6ea8fe;
    }

    li.nav-item > a.nav-link {
      padding: 9px 15px;
      font-weight: 500;
      line-height: 32px;
      color: $white;
      font-size: 14px;

      &:hover,
      &:focus {
        color: rgba(255 255 255 / 80%);
        background: none;
      }
    }

    .dropdown-menu {
      @include media-breakpoint-up(md) {
        padding: 0;

        a.dropdown-item {
          padding: 10px 20px;
          font-size: 14px;
        }
      }
    }
  }
}
