// Use custom font
@import "~@fontsource/open-sans/index.css";
@import "~@fontsource/open-sans/700.css"; // font-weight: bold
@import "~@fontsource/open-sans/300-italic.css";

$font-family-base: "Open Sans", sans-serif;

// Custom colors
$co2m-dark-blue: #003253;
$co2m-dark-green: #0fab4d;
$co2m-blue: #1482cc;

// Overwrite Bootstrap colors
$blue: $co2m-blue;
$green: $co2m-dark-green;
$info: $blue; // Instead of Bootstrap $cyan
$link-color: $co2m-blue;
$alert-bg-scale: -90%;
$alert-border-scale: -90%;
$alert-color-scale: 30%;
$light: #ebeceb;
$dropdown-link-active-bg: $link-color;

// Import bootstrap css
@import "~bootstrap/scss/bootstrap";
@import "global.scss";
@import "top_nav.scss";
@import "app_switcher.scss";
